<template>
  <div>
    <b-card-header>
      <b-card-title>
        <h1>
          Dashboard
        </h1>
      </b-card-title>
    </b-card-header>
    <b-row class="mt-1 mb-1">
      <b-col class="text-right">
        <h5>Última Sincronização: {{ lastSyncDate }}</h5>
      </b-col>
    </b-row>
    <b-row style="padding-top: 4px;">
      <b-col md="3">
        <b-card
          class="kpi-card"
        >
          <b-card-body style="padding: 5px;height: 80%">
            <div style="background-color: green; padding-top: 10px;  padding-bottom: 10px; border-radius: 10px">
              <h2 class="font-weight-bolder mb-1">
                {{ kpi.total }}
              </h2>
              <br>
              <h6>Dispositivos</h6>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="2">
        <b-card
          class="kpi-card"
        >
          <b-card-body style="padding: 5px;height: 80%">
            <div style="background-color: var(--white); padding-top: 10px;  padding-bottom: 10px; border-radius: 10px">
              <h2
                class="font-weight-bolder mb-1;"
                style="color: black"
              >
                {{ kpi.provisioned }}
              </h2>
              <br>
              <h6 style="color: black">
                Provisionados
              </h6>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card
          class="kpi-card"
        >
          <b-card-body style="padding: 5px;height: 80%">
            <div style="background-color:   var(--danger); padding-top: 10px;  padding-bottom: 10px; border-radius: 10px">
              <h2 class="font-weight-bolder mb-1">
                {{ kpi.unprovisioned }}
              </h2>
              <br>
              <h6>Desprovisionados</h6>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="2">
        <b-card
          class="kpi-card"
        >
          <b-card-body style="padding: 5px;height: 80%">
            <div style="background-color: var(--primary); padding-top: 10px;  padding-bottom: 10px; border-radius: 10px">
              <h2 class="font-weight-bolder mb-1">
                {{ kpi.licensed }}
              </h2>
              <br>
              <h6>Licenciados</h6>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="2">
        <b-card
          class="kpi-card"
        >
          <b-card-body style="padding: 5px;height: 80%">
            <div style="background-color:  var(--warning); padding-top: 10px;  padding-bottom: 10px; border-radius: 10px">
              <h2 class="font-weight-bolder mb-1">
                {{ kpi.unlicensed }}
              </h2>
              <br>
              <h6>Não licenciados</h6>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <i2-divider />
    <b-row>
      <b-col
        sm="6"
        md="6"
      >
        <b-card>
          <b-card-title class="color-black">
            Quero ver gráficos
          </b-card-title>
          <b-card-body>
            Aqui você pode ver em gráficos as informações referente a sua unidade.
          </b-card-body>
          <b-card-footer>
            <b-button block variant="primary" @click="redirectTo('graphs')">
              Acessar
              <FeatherIcon icon="ArrowRightIcon" class="ml-1" />
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="6"
      >
        <b-card>
          <b-card-title class="color-black">
            Quero ver tabelas
          </b-card-title>
          <b-card-body>
            Aqui você pode ver em tabelas as informações referente a sua unidade.
          </b-card-body>
          <b-card-footer>
            <b-button block variant="primary" @click="redirectTo('tables')">
              Acessar
              <FeatherIcon icon="ArrowRightIcon" class="ml-1" />
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  components: {},
  data: () => ({
    lastSyncDate: '-',
    kpi: {
      total: 0,
      provisioned: 0,
      unprovisioned: 0,
      licensed: 0,
      unlicensed: 0,
    },
  }),

  async mounted() {
    const dashboard = await this.$http.get('/dashboard')
    this.kpi = dashboard.kpis
    this.lastSyncDate = await this.$http.get('clients/last-sync')
  },
  methods: {
    redirectTo(routeName) {
      this.$router.push({ name: routeName })
    },
  },
}
</script>
<style scoped>
.kpi-card {
  color: white;
}

.kpi-card h2, h3, h4, h6 {
  color: white;
}

.kpi-card .card-header {
  text-align: left;
}

.kpi-card .card-body {
  text-align: center;
}

.color-black {
  color: #000;
}
</style>
